<template>
  <div class="mobile-list">
    <div class="mobile-list-content">
      <orderScroll ref="scroll" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <div class="mobile-list-card" v-for="(item, index) in items" :key="index" @click="itemClick(item)">
          <div class="mobile-list-row">
            <div class="base-font-color">{{ item.order_type }}</div>
            <div class="light-dark-font-color">
              {{ formatDate(item.time, "yyyy-MM-dd hh:mm") }}
            </div>
          </div>
          <div class="mobile-list-row">
            <div class="dark-font-color" style="line-height: 20px">
              {{ item.content }}
            </div>
          </div>
        </div>

      </orderScroll>
    </div>
  </div>
</template>

<script>
import { GetTaskulous } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadListAction } from "@/utils/action.js";

const wx = window.wx;
export default {
  data: function () {
    return {
      items: [],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: false,
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      filterOption: {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
        orderTypeKey: null,
      },
      enterkey: [],
      keyword: "",
    };
  },
  methods: {
    formatDate: formatDate,
    firstPage: function () {
      this.pageIndex = 1;
      this.dataRequest();
    },
    nextPage: function () { },
    itemClick: function (item) {
      wx.miniProgram.redirectTo({
        url: "/" + item.h5_url,
      });
    },
    dataRequest: function () {
      loadListAction(this, GetTaskulous, { state: 0 }, (data) => {
        this.total = data.totalCount;
        if (this.pageIndex == 1) {
          this.items = [];
        }
        if (data.length > 0) {
          this.items = this.items.concat(data);
          this.pageIndex += 1;
        }
        this.hasMore = this.total > this.items.length;
        this.$refs.scroll.forceUpdate(true);
      });
    },
  },
  mounted: function () {
    this.firstPage();
  }
};
</script>
